// require('@fortawesome/fontawesome-free/js/all.js');
// require('@fortawesome/fontawesome-free/js/solid.js');
// require('@fortawesome/fontawesome-free/js/regular.js');
// require('@fortawesome/fontawesome-free/js/v4-shims.js');
// require('@fortawesome/fontawesome-free/js/brands.js');
// require('@fortawesome/fontawesome-free/js/fontawesome.js');

require("@fortawesome/fontawesome-free/scss/fontawesome.scss");
require("@fortawesome/fontawesome-free/scss/solid.scss");
require("@fortawesome/fontawesome-free/scss/regular.scss");
// require("@fortawesome/fontawesome-free/scss/brands.scss");

const $ = require('jquery');
global.$ = global.jQuery = $;

require('jquery.easing');

const bootstrap = require('bootstrap')
global.bootstrap = bootstrap

require('select2')

require('./styles/global.scss');

import { startStimulusApp } from '@symfony/stimulus-bridge';

export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.(j|t)sx?$/
));
